<template>
  <div>
    <div class="content-tile mb-12">
      <div class="su-title">
        <i class="el-icon-document-copy"></i>
        <span>消息中心</span>
      </div>
      <div>
        <el-button type="primary" @click="batchDelClick">批量删除</el-button>
      </div>
    </div>

    <div class="message-center-table">
      <div class="table">
        <!--表格-->


        <el-table
            ref="table"
            :data="tableData"
            @selection-change="handleSelectionChange"
            border
            style="width: 100%"
            :row-class-name="tableRowClassName"
            :header-cell-style="{'color': '#18a78a'}"
            v-loading="$store.state.loading"
        >
          <el-table-column
              type="selection"
              min-width="40px"
              align="left"
              :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
              label="消息内容"
              prop="messageContent"
              min-width="170px"
              align="left"
              :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
              label="创建时间"
              prop="creationTime"
              width="170px"
              align="center"
              :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="操作" width="180px" align="center">
            <template slot-scope="scope">
              <el-button type="danger" @click="delTableRow(scope.row.id)">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!--分页-->
    <div class="footerPage">
      <div>
        <!--<el-button type="primary">批量删除</el-button>-->
      </div>
      <el-pagination
          background
          @current-change="pageCurrentChange"
          :current-page="currentPage"
          :page-size="30"
          layout="total, prev, pager, next"
          :total="total">
      </el-pagination>
    </div>

  </div>
</template>

<script>
import {noticeList, noticeDel} from '@/api/module/messageCenter'

export default {

  props: [],
  data() {
    return {
      currentPage: 1,  // 当前页数
      total: 20,//总数

      tableSelectId: [],//表格多选后的数组[1,2,3]
      // 表格内容
      tableData: [
      //     {
      //   id: 1,
      //   messageContent: '早点起来吃饭',//消息内容
      //   creationTime: '2015-05-12 15:45:55',//创建时间
      //   status: 1, //状态，1 已完成 0未完成
      // }
      ]
    };
  },
  created() {
    this.initLoad();
  },
  mounted() {
  },
  methods: {
    //初始化
    initLoad() {
      this.$store.state.loading = true;
      let params={
        page:this.currentPage
      }
      noticeList(params).then((result) => {
        let res = result.data
        this.currentPage = res.current_page;
        this.total = res.total;
        this.tableData = res.data.map(item => ({
          id: item.id,
          messageContent: item.content,//消息内容
          creationTime: this.Utils.timeDateSeconds(item.created_at),//创建时间
          status: item.id, //状态，1 已完成 0未完成
        }));
      })
    },
    //分页
    pageCurrentChange(val) {
      this.currentPage=val;
      this.initLoad();
      console.log(`当前页: ${val}`);
    },

    //表格 点击删除
    delTableRow(id) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          ids: id
        }
        noticeDel(data).then(() => {
          this.initLoad();
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //批量删除
    batchDelClick(){

      if(this.tableSelectId.length==0){
        this.$message({
          type: 'error',
          message: '请选择要删除的信息'
        });
        return false;
      }
      this.delTableRow(this.tableSelectId.toString())
    },
    //表格 多选后的值
    handleSelectionChange(selection) {
      let tableDataROW = [];
      selection.forEach((value) => {
        this.tableData.forEach((val) => {
          if (value.id == val.id) {
            tableDataROW.push(value.id);
          }
        });
      });
      //把多选完后的数组保存起来
      this.tableSelectId = [];
      this.tableSelectId = tableDataROW;
    },

    //表格 第几行显示什么颜色
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 0) {
        return 'warning-row';
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
